import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
// import Heading from "@tiptap/extension-heading";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
// import Gapcursor from "@tiptap/extension-gapcursor";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Typography from "@tiptap/extension-typography";
import FontSize from "tiptap-extension-font-size";
import FontFamily from "@tiptap/extension-font-family";
//import Link from "@tiptap/extension-link";
import CustomLink from "./CustomLink.js";
import GlobalStyleAttributes from "./GlobalStyleAttributes";

import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import ClearIcon from "@mui/icons-material/Clear";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import { Extensions } from "@tiptap/core";

import { SmilieReplacer } from "./SmilieReplacer.ts";
import CloseIcon from "@mui/icons-material/Close";

import CollectionsIcon from "@mui/icons-material/Collections";

// import { generateJSON, generateHTML } from "@tiptap/html";

import { useDispatch } from "react-redux";
import { update } from "../../store";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
// import FormatColorTextIcon from "@mui/icons-material/FormatColorText";

// import Document from "@tiptap/extension-document";
// import Paragraph from "@tiptap/extension-paragraph";
// import Text from "@tiptap/extension-text";
import pretty from "pretty";

import CustomImage from "./CustomImage";
import ImageDialog from "../widgets/ImageDialog";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  // TextField,
  // Radio,
  // RadioGroup,
  // Modal,
  IconButton,
  Fab,
  // TextField,
  // Grid,
  InputLabel,
  InputBase,
  // TextField,
} from "@mui/material";
import bgColor from "./bgColor.ts";
import CustomDiv from "./CustomDiv.js";
import LabelStyle from "./LabelStyle.js";
import HeroBlock from "./HeroBlock.js";
import PlaylistBlock from "./PlaylistBlock.js";
import CustomTableCell from "./CustomTableCell.js";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
// import Image from "@tiptap/extension-image";
import FlexBlock from "./FlexBlock.js";
import { appConfig } from "../../config.js";
import webSafeFonts from "./webSafeFonts.js";
import "./rte.css";
import "./styles.css";
import { SettingsPower } from "@mui/icons-material";

const TipTapEditor = ({ setBlock, block, email = false }) => {
  const location = useLocation();
  const NODEURL = appConfig.NODEURL;
  const theme = useTheme();
  const [imageUrl, setImageUrl] = useState("");
  const [isSourceView, setSourceView] = useState(false);
  const [sourceContent, setSourceContent] = useState(""); // For source view content
  const [colorText, setColorText] = useState(false);
  const [heros, setHeros] = useState("");
  const [playlists, setPlaylists] = useState("");
  const [playlist, setPlaylist] = useState("");
  const [cellColor, setCellColor] = useState("#000000");
  const [cellBgColor, setCellBgColor] = useState("#ffffff");
  const [backColor, setBackColor] = useState("#ffffff");
  const [fontSizeAttr, setFontSizeAttr] = useState("rem");
  const [fontSize, setFontSize] = useState("1");
  const [fontFamily, setFontFamily] = useState("fonts...");
  const [label, setLabel] = useState("");
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState(1);
  const [bgHex, setBgHex] = useState("#ffffff");
  const [textHex, setTextHex] = useState("#000000");
  // const [bgScope, setBgScope] = useState("bg");

  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(200);
  const [aspectRatio, setAspectRatio] = useState(width / height); // Maintain the aspect ratio

  const [float, setFloat] = useState("none");

  const [showBar, setShowBar] = useState(true);

  const handleFloat = (event) => {
    setFloat(event.target.value);
  };

  // const count = useSelector((state) => state.counter.count); // Access the state

  // const rteHtmlContent = useSelector((state) => state.rte.html);
  // const rteJsonContent = useSelector((state) => state.rte.json);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setAspectRatio(width / height);
  //   console.log(width / height);
  // }, [width, height, aspectRatio]);

  const fetchHeros = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/hero`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      setHeros(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/playlists`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      setPlaylists(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    setPosition(block.position);
    setLabel(block.label);
    setTitle(block.title);
    setBackColor(theme.palette.primary.main);
    setCellBgColor(theme.palette.primary.main);
    setCellColor(theme.palette.primary.contrastText);
    fetchHeros();
    fetchPlaylists();
    return () => {};
  }, []);
  const [selectedHero, setSelectedHero] = useState({ title: "heros..." });
  const [selectedPlaylist, setSelectedPlaylist] = useState({
    name: "playlists...",
  });

  const fetchPlaylist = async () => {
    if (!selectedPlaylist.id) {
      return;
    }
    const resp = await axios.get(
      `${appConfig.NODEURL}api/playlists/${selectedPlaylist.id}`
    );
    console.log(resp.data);
    setPlaylist(resp.data);
  };

  useEffect(() => {
    // console.log(selectedPlaylist);
    fetchPlaylist();
  }, [selectedPlaylist]);

  const insertHero = () => {
    const heroMarkup = {
      type: "heroBlock", // This must match the type name in the custom node
      attrs: {
        // imgMarkup: selectedHero.imgmarkup,
        hero: selectedHero,
      },
    };

    // structured content is passed here
    editor.commands.insertHeroBlock(selectedHero);
  };

  const insertPlaylist = () => {
    editor.commands.insertPlaylistBlock(playlist);
  };

  const removePlayList = () => {
    editor.commands.removePlayListBlock(playlist);
  };

  const toggleSourceView = () => {
    if (!isSourceView) {
      // Switching to source view: Save editor content as pretty-printed JSON string
      const jsonContent = editor.getJSON(); // Get JSON content
      setSourceContent(JSON.stringify(jsonContent, null, 2)); // Convert to string
    } else {
      try {
        // Switching back to editor view: Parse the JSON string back to an object
        const parsedContent = JSON.parse(sourceContent); // Convert back to JSON
        editor.commands.setContent(parsedContent); // Set the content
      } catch (error) {
        console.error("Invalid JSON content:", error);
        alert("Invalid JSON content. Please fix any syntax errors.");
      }
    }
    setSourceView(!isSourceView); // Toggle the view
  };

  // const toggleSourceView = () => {
  //   if (!isSourceView) {
  //     // Convert JSON to pretty-printed string for source view
  //     const jsonContent = editor.getJSON();
  //     setSourceContent(JSON.stringify(jsonContent, null, 2));
  //   } else {
  //     try {
  //       // Validate JSON input before applying it to the editor
  //       const parsedContent = JSON.parse(sourceContent);
  //       editor.commands.setContent(parsedContent);
  //     } catch (error) {
  //       console.error(`Invalid JSON content: ${error.message}`, error);
  //       alert("Please fix the JSON syntax errors before switching back.");
  //       return; // Stop if invalid JSON
  //     }
  //   }
  //   setSourceView(!isSourceView);
  // };

  const handleSourceChange = (e) => {
    setSourceContent(e.target.value); // Update the state for the textarea
    // setSourceContent(JSON.stringify(jsonContent, null, 2));
  };

  const handleSubmit = (e) => {
    console.log(`postion: ${position}`);
    e.preventDefault();
    const json = editor.getJSON(); // JSON format for the content column
    const html = editor.getHTML(); // HTML format for the markup column

    const send = { title: title, label: label, html: html, json: json };
    // console.log(send);
    dispatch(update(send));
    // Send data to the server
    fetch(`${NODEURL}api/blocks/${block.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        block_type: block.block_type,
        content: json,
        markup: html,
        position: position,
        label: label,
        title: title,
      }),
    });
  };

  const toggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const toggleUnderline = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  // import { Image as TipTapImage } from '@tiptap/extension-image';

  // const CustomImage = Image.extend({
  //   addAttributes() {
  //     return {
  //       ...this.parent?.(),
  //       style: {
  //         default: null,
  //         parseHTML: (element) => element.getAttribute("style"),
  //         renderHTML: (attributes) => {
  //           if (!attributes.style) {
  //             return {};
  //           }
  //           return {
  //             style: attributes.style,
  //           };
  //         },
  //       },
  //       className: {
  //         default: null,
  //         parseHTML: (element) => element.getAttribute("class"),
  //         renderHTML: (attributes) => {
  //           if (!attributes.className) {
  //             return {};
  //           }
  //           return {
  //             class: attributes.className,
  //           };
  //         },
  //       },
  //     };
  //   },
  // });

  const editor = useEditor({
    extensions: [
      CustomDiv,
      CustomImage,
      LabelStyle,
      HeroBlock,
      PlaylistBlock,
      FlexBlock,
      bgColor,
      StarterKit,
      Underline,
      TextStyle,
      Color,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      CustomLink,
      // CustomLink.configure({
      //   openOnClick: false,
      //   autolink: true,
      //   defaultProtocol: "https",
      // }),
      CustomTableCell,
      TableHeader,
      Typography,
      FontSize,
      FontFamily,
      // Image,
      SmilieReplacer,
      GlobalStyleAttributes,
    ],
    content: block?.content ? block.content : "",
    onFocus: ({ editor }) => {
      // console.log(editor);
      const html = editor.getHTML();
      const json = editor.getJSON();
      // dispatch(increment());
      const send = { title: title, label: label, html: html, json: json };
      // console.log(send);
      dispatch(update(send));
    },
    onCreate: ({ editor }) => {
      console.log(editor);
      const html = editor.getHTML();
      const json = editor.getJSON();
      // dispatch(increment());
      const send = { title: title, label: label, html: html, json: json };
      // console.log(send);
      dispatch(update(send));
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const json = editor.getJSON();
      const send = { title: title, label: label, html: html, json: json };
      dispatch(update(send));
    },
  });

  // const addImage = useCallback(() => {
  const addImage = (url) => {
    console.log(appConfig.HOME + imageUrl);
    // const url = url;
    // console.log(url);

    const imgAttributes = {
      src:
        imageUrl.substring(0, 4) !== "http"
          ? appConfig.HOME + "uploads/" + imageUrl
          : imageUrl,
      alt: "this is the alt",
      title: "this is the title",
      className: "cutecmsImage",
      // style: "width:100px",
      // height: height || undefined,
      style: `width:${width}px;height:${height}px;float: ${float}`,
    };

    if (url) {
      editor.chain().focus().setImage(imgAttributes).run();
    }
  };

  const AdvTools = () => {
    if (location.pathname === "/sendmail") {
      return null;
    }
    return (
      <div className="toolbar">
        <button disabled={!selectedHero.id} onClick={insertHero}>
          inject hero
        </button>
        {heros && (
          <Select
            style={{ height: 20, fontSize: 14 }}
            size="small"
            value={selectedHero}
            onChange={(e) => setSelectedHero(e.target.value)}
          >
            <MenuItem value={selectedHero}>{selectedHero.title}</MenuItem>
            {heros.map((hero, index) => {
              return (
                <MenuItem key={index + "heros2"} value={hero}>
                  {hero.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
        <button disabled={!selectedPlaylist.id} onClick={insertPlaylist}>
          inject playlist
        </button>
        {playlists && (
          <Select
            style={{ height: 20, fontSize: 14 }}
            size="small"
            value={selectedPlaylist}
            onChange={(e) => setSelectedPlaylist(e.target.value)}
          >
            <MenuItem key={-1} value={selectedPlaylist}>
              {selectedPlaylist.name}
            </MenuItem>
            {playlists.map((list, index) => {
              return (
                <MenuItem key={index + "playlists"} value={list}>
                  {list.name}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </div>
    );
  };

  const handleDeleteBlock = async (id) => {
    try {
      const resp = await axios.delete(`${appConfig.NODEURL}api/blocks/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });

      setBlock(false);
    } catch (err) {
      console.error("Error deleting block:", err);
    }
  };

  const clearEditor = () => {
    editor.commands.setContent("");
    setSourceContent(
      JSON.stringify(
        {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
        null,
        3
      )
    );
    const html = editor.getHTML();
    const json = editor.getJSON();
    const send = { html: html, json: json };
    dispatch(update(send));
  };

  // const ImageDialog = () => {
  //   const [show, setShow] = useState(false);
  //   const [images, setImages] = useState([]);
  //   const fetchImages = async () => {
  //     const resp = await axios.get(`${appConfig.NODEURL}api/images`);
  //     // console.log(resp);
  //     setImages(resp.data);
  //     localStorage.setItem("imageList", JSON.stringify(resp.data));
  //   };

  //   useEffect(() => {
  //     const localImages = localStorage.getItem("imageList");
  //     if (localImages) {
  //       setImages(JSON.parse(localImages));
  //       return;
  //     }
  //     fetchImages();
  //   }, [block]);

  //   if (!show) {
  //     return (
  //       <IconButton
  //         sx={{
  //           ...theme.typography.button2,
  //           margin: 0,
  //           padding: 1,
  //           height: 24,
  //         }}
  //         title="image collections"
  //         onClick={() => setShow(true)}
  //       >
  //         <CollectionsIcon style={{ fontSize: 20 }} />
  //       </IconButton>
  //     );
  //   }

  //   return (
  //     <Dialog
  //       disableEscapeKeyDown={true}
  //       fullWidth={true}
  //       maxWidth="md"
  //       open={true}
  //       onClose={() => setShow(false)}
  //       aria-labelledby="choose image"
  //       aria-describedby="dialog-with-image-list"
  //       scroll="paper"
  //     >
  //       <DialogContent>
  //         <div style={{ position: "absolute", top: 0, right: 0 }}>
  //           <Fab onClick={() => setShow(false)}>
  //             <CloseIcon />
  //           </Fab>
  //         </div>
  //         <h1>Images</h1>
  //         <Button sx={theme.typography.button2} onClick={fetchImages}>
  //           refresh
  //         </Button>
  //         {images &&
  //           images.map((image, index) => {
  //             const smallestVariant = image.variants.reduce(
  //               (smallest, current) => {
  //                 return current.size < smallest.size ? current : smallest;
  //               }
  //             );
  //             const imgUrl = `/uploads/${smallestVariant.file}`;

  //             return (
  //               <div
  //                 key={index + "images"}
  //                 style={{
  //                   display: "flex",
  //                   flexWrap: "wrap",
  //                   gap: 1,
  //                   alignItems: "flex-start",
  //                   border: "1px solid green",
  //                   marginBottom: 30,
  //                   borderRadius: 10,
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     display: "flex",
  //                     flexDirection: "column",
  //                     maxHeight: 150,
  //                     overflow: "clip",
  //                   }}
  //                 >
  //                   {image.baseName}
  //                   <img
  //                     style={{
  //                       maxWidth: 200,
  //                     }}
  //                     src={`${appConfig.HOME}${imgUrl}`}
  //                   />
  //                 </div>
  //                 <div
  //                   style={{
  //                     display: "flex",
  //                     flexWrap: "wrap",
  //                     align: "flex-start",
  //                     border: "1px none black",
  //                     gap: 10,
  //                     justifyContent: "space-evenly",
  //                   }}
  //                 >
  //                   {image.variants
  //                     .sort((a, b) => {
  //                       if (Number(a.size) < Number(b.size)) {
  //                         return 1;
  //                       }
  //                       if (Number(a.size) > Number(b.size)) {
  //                         return -1;
  //                       }
  //                       return 0;
  //                     })
  //                     .reverse()
  //                     .map((m, index) => {
  //                       return (
  //                         <div
  //                           key={index}
  //                           style={{ maxHeight: 200, overflow: "clip" }}
  //                         >
  //                           {/* {index === 0 ? (
  //                           <img
  //                             style={{ maxWidth: 300, minWidth: 100 }}
  //                             src={`/uploads/${m.file}`}
  //                           />
  //                         ) : null} */}
  //                           {/* {index === 0 && (
  //                           <img
  //                             style={{ maxWidth: 300, minWidth: 100 }}
  //                             src={`/uploads/${m.file}`}
  //                           />
  //                         )} */}
  //                           {/* <pre>{JSON.stringify(m, null, 3)}</pre> */}
  //                           <Button
  //                             value={JSON.stringify(m)}
  //                             onClick={(e) => {
  //                               console.log(m);
  //                               setHeight(m.dimensions.height);
  //                               setWidth(m.dimensions.width);
  //                               setAspectRatio(
  //                                 m.dimensions.width / m.dimensions.height
  //                               );
  //                               setImageUrl(m.file);
  //                               // setImageUrl(
  //                               //   `${appConfig.HOME}uploads/${
  //                               //     JSON.parse(e.target.value).file
  //                               //   }`
  //                               // );
  //                               setShow(false);
  //                             }}
  //                             sx={{
  //                               ...theme.typography.button,
  //                             }}
  //                           >
  //                             Select
  //                           </Button>
  //                           <div
  //                             style={{
  //                               display: "flex",
  //                               flexDirection: "column",
  //                             }}
  //                           >
  //                             <div>
  //                               size: {Number(m.size).toLocaleString("en-US")}
  //                             </div>
  //                             <div>width: {m.dimensions.width}</div>
  //                             <div>height: {m.dimensions.height}</div>
  //                           </div>
  //                         </div>
  //                       );
  //                     })}
  //                 </div>
  //               </div>
  //             );
  //           })}
  //       </DialogContent>
  //     </Dialog>
  //   );
  // };

  const [htmlSource, setHtmlSource] = useState(false);
  const [showHtmlSource, setShowHtmlSource] = useState(false);

  if (!editor) {
    return null;
  }

  //   const toggleSourceView = () => {
  //     setSourceView(!isSourceView);
  //   };

  const setDimensions = (e) => {
    console.log(aspectRatio);
    const value = parseInt(e.target.value, 10); // Parse the input value
    if (e.target.name === "width") {
      setWidth(value);
      setHeight(Math.round(value / aspectRatio)); // Adjust height based on new width
    } else if (e.target.name === "height") {
      setHeight(value);
      setWidth(Math.round(value * aspectRatio)); // Adjust width based on new height
    }
  };

  const validateDimension = (e) => {
    const value = parseInt(e.target.value, 10);
    if (e.target.name === "width") {
      if (value < 100) {
        setWidth(100);
        setHeight(Math.round(100 / aspectRatio));
      }
    } else if (e.target.name === "height") {
      if (value < 80) {
        setHeight(80);
        setWidth(Math.round(80 * aspectRatio));
      }
    }
  };

  // const [htmlSource, setHtmlSource] = useState(false);

  const doCleanup = () => {
    const cleanedContent = cleanup(editor.getJSON());
    editor.commands.setContent(cleanedContent);
  };

  function cleanup(doc) {
    if (!doc || typeof doc !== "object") return doc;

    // If the current node is a paragraph with no content, return null to indicate removal
    if (
      doc.type === "paragraph" &&
      (!doc.content || doc.content.length === 0)
    ) {
      return null;
    }

    // If the node has content, clean up its children recursively
    if (Array.isArray(doc.content)) {
      doc.content = doc.content
        .map(cleanup) // Clean each child node
        .filter(Boolean); // Remove null entries
    }

    return doc;
  }

  // Main View
  return (
    <div
      style={{
        // backgroundColor: theme.palette.grey[800],
        display: "flex",
        flexDirection: "column",
        position: "relative",
        gap: 16,
      }}
    >
      <div
        style={{
          display: setBlock ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          right: -10,
          top: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: -20,
            top: -20,
            zIndex: theme.zIndex.tooltip,
            display: "flex",
            gap: 10,
            // backgroundColor: theme.palette.primary.light,
            // color: theme.palette.primary.contrastText,
            borderRadius: 15,
          }}
        >
          <ConfirmButtons
            icon={<DeleteForeverIcon style={{ fontSize: 35 }} />}
            action={handleDeleteBlock}
            args={block.id}
          />
          <Fab
            size="small"
            onClick={() => {
              setShowBar((prev) => !prev);
            }}
          >
            {showBar ? (
              <ExpandLessIcon title="hide toolbar" style={{}} />
            ) : (
              <ExpandMoreIcon
                title="show toolbar"
                style={{
                  backgroundColor: theme.palette.info.main,
                  color: theme.palette.info.contrastText,
                  borderRadius: 50,
                  fontSize: 28,
                }}
              />
            )}
          </Fab>
          <Fab
            size="small"
            onClick={() => {
              setBlock(false);
            }}
          >
            <CloseIcon title="Close" />
          </Fab>
        </div>
      </div>
      <Box
        sx={{
          display: showBar ? "flex" : "none",
          fontSize: "smaller",
          // display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 0.3,
          backgroundColor: theme.palette.secondary.light,
          position: "sticky",
          top: 20,
          left: 0,
          zIndex: theme.zIndex.appBar,
          padding: 0.2,
          borderRadius: 2,
          boxShadow: theme.shadows[3], // Optional for a shadow effect
        }}
      >
        <AdvTools />
        <div style={{ position: "relative" }} className="toolbar">
          <ImageDialog
            setHeight={setHeight}
            setWidth={setWidth}
            setAspectRatio={setAspectRatio}
            setImageUrl={setImageUrl}
          />

          <input
            style={{ fieldSizing: "content" }}
            // onFocus={(e) => {

            //   e.currentTarget.style.fieldSizing = "content";
            // }}
            // onBlur={(e) => {
            //   e.currentTarget.style.fieldSizing = "";
            // }}
            onChange={(e) => setImageUrl(e.target.value)}
            value={
              imageUrl && imageUrl.substring(0, 4) !== "http"
                ? `${appConfig.HOME}uploads/${imageUrl}`
                : imageUrl
            }
            placeholder="image URL"
            type="text"
            name="image_url"
          />
          <div
            style={{
              display: !imageUrl ? "none" : "flex",
              flexWrap: "wrap",
              gap: 5,
            }}
          >
            <input
              min="100"
              style={{ width: 55 }}
              type="number"
              value={width}
              onChange={setDimensions}
              onBlur={validateDimension}
              name="width"
            />
            <input
              min="80"
              style={{ width: 55 }}
              type="number"
              value={height}
              onChange={setDimensions}
              name="height"
              onBlur={validateDimension}
            />

            <InputLabel
              style={{ height: 20, fontSize: 14, margin: 0, padding: 0 }}
              id="float-select-label"
            >
              Float&nbsp;
              <Select
                style={{ height: 20, fontSize: 14 }}
                size="small"
                labelId="float-select-label"
                id="float-select"
                value={float}
                onChange={handleFloat}
                label="Float"
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </Select>
            </InputLabel>
            <button onClick={() => addImage(imageUrl)} disabled={!imageUrl}>
              inject image
            </button>
          </div>
        </div>

        <div className="toolbar">
          <IconButton
            style={{
              backgroundColor: editor.isActive("bold") ? "#0a0" : "#ffffff",
            }}
            onClick={toggleBold}
          >
            <FormatBoldIcon style={{ fontSize: 12 }} />
          </IconButton>
          <IconButton
            style={{
              backgroundColor: editor.isActive("italic") ? "#0a0" : "#ffffff",
            }}
            onClick={toggleItalic}
          >
            <FormatItalicIcon style={{ fontSize: 12 }} />
          </IconButton>
          <IconButton
            style={{
              backgroundColor: editor.isActive("underline")
                ? "#0a0"
                : "#ffffff",
            }}
            onClick={toggleUnderline}
          >
            <FormatUnderlinedIcon style={{ fontSize: 12 }} />
          </IconButton>

          <IconButton
            style={{ margin: 0, padding: 0 }}
            onClick={() => editor.commands.unsetAllMarks()}
            // disabled={!editor.can().liftListItem("listItem")}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className="toolbar">
          <Select
            style={{ height: 20, fontSize: 14 }}
            size="small"
            onChange={(e) => {
              setFontSize(e.target.value);
              editor
                .chain()
                .focus()
                .setFontSize(`${e.target.value}${fontSizeAttr}`)
                .run();
            }}
            value={fontSize}
          >
            {[...Array(100).keys()].map((item, index) => {
              return (
                <MenuItem key={index + "sizes"} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            style={{ height: 20, fontSize: 14 }}
            size="small"
            onChange={(e) => setFontSizeAttr(e.target.value)}
            value={fontSizeAttr}
          >
            <MenuItem value="rem">rem</MenuItem>
            <MenuItem value="em">em</MenuItem>
            <MenuItem value="pt">pt</MenuItem>
            <MenuItem value="px">px</MenuItem>
          </Select>
          <button
            onClick={() =>
              editor
                .chain()
                .focus()
                .setFontSize(`${fontSize}${fontSizeAttr}`)
                .run()
            }
          >
            apply size
          </button>
        </div>
        <div className="toolbar">
          <Select
            style={{ height: 20, fontSize: 14, width: 120 }}
            size="small"
            value={fontFamily}
            // onChange={(e) => setFontFamily(e.target.value)}
            onChange={(e) => {
              setFontFamily(e.target.value);
              editor.chain().focus().setFontFamily(e.target.value).run();
            }}
          >
            <MenuItem value="fonts...">fonts...</MenuItem>
            {webSafeFonts.map((font, index) => {
              return (
                <MenuItem key={index + "fonts"} value={font}>
                  {font}
                </MenuItem>
              );
            })}
          </Select>
          <button
            onClick={() =>
              editor.chain().focus().setFontFamily(fontFamily).run()
            }
            disabled={fontFamily === "fonts..."}
            // style={{ display: fontFamily === "fonts..." ? "none" : "inline" }}
          >
            apply font
          </button>

          <button
            onClick={() => {
              editor.commands.unsetFontFamily();
              setFontFamily("fonts...");
            }}
            data-test-id="unsetFontFamily"
          >
            remove font
          </button>
        </div>

        <div className="toolbar">
          <button
            style={{
              fontSize: 22,
              backgroundColor: editor.isActive("heading", { level: 1 })
                ? "#0a0"
                : "#ffffff",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
          >
            H1
          </button>

          <button
            style={{
              fontSize: 20,
              backgroundColor: editor.isActive("heading", { level: 2 })
                ? "#0a0"
                : "#ffffff",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
          >
            H2
          </button>
          <button
            style={{
              fontSize: 18,
              backgroundColor: editor.isActive("heading", { level: 3 })
                ? "#0a0"
                : "#ffffff",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
          >
            H3
          </button>

          <button
            style={{
              fontSize: 17,
              backgroundColor: editor.isActive("heading", { level: 4 })
                ? "#0a0"
                : "#ffffff",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }
          >
            H4
          </button>

          <button
            style={{
              fontSize: 15,
              backgroundColor: editor.isActive("heading", { level: 5 })
                ? "#0a0"
                : "#ffffff",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 5 }).run()
            }
          >
            H5
          </button>
        </div>

        <div className="toolbar">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 0,
              width: "fit-content",
              // maxWidth: 400,

              // padding: 1,
              // margin: 1,
            }}
          >
            {Object.entries(theme.palette).map((palette, index) => {
              // this filters on just the colors that have main,light,dark:
              if (palette[1].main) {
                return (
                  <div key={index + "palette"}>
                    <Button
                      style={{
                        ...theme.typography.button2,
                        backgroundColor: palette[1].main,
                        color: palette[1].contrastText,
                      }}
                      key={index + "palette2"}
                      onClick={() =>
                        editor
                          .chain()
                          .focus()
                          // .setColor(palette[1].contrastText)
                          .setBackColor(palette[1].main)
                          .run()
                      }
                    >
                      {palette[0]}
                    </Button>
                    <IconButton
                      key={index + "palette3"}
                      onClick={() =>
                        editor
                          .chain()
                          .focus()
                          .setColor(palette[1].contrastText)
                          // .setBackColor(palette[1].main)
                          .run()
                      }
                    >
                      <TextFormatIcon
                        style={{
                          width: 16,
                          height: 16,
                          backgroundColor: palette[1].main,
                          color: palette[1].contrastText,
                        }}
                      />
                    </IconButton>
                  </div>
                );
              }
            })}
            <IconButton
              style={{ margin: 0, padding: 0 }}
              onClick={() => editor.commands.unsetAllMarks()}
              // disabled={!editor.can().liftListItem("listItem")}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </div>

        <div className="toolbar">
          {Object.entries(theme.palette).map((palette, index) => {
            // this filters on just the colors that have main,light,dark:
            if (palette[1].main) {
              return (
                <div key={index + "palette"}>
                  <IconButton
                    key={index + "palette3"}
                    onClick={() =>
                      editor
                        .chain()
                        .focus()
                        .setColor(palette[1].main)
                        // .setBackColor("#ffffff")
                        .run()
                    }
                  >
                    <TextFormatIcon
                      style={{
                        backgroundColor: "#ffffff",
                        color: palette[1].main,
                      }}
                    />
                  </IconButton>
                </div>
              );
            }
          })}
          <IconButton
            style={{ margin: 0, padding: 0 }}
            onClick={() => editor.commands.unsetAllMarks()}
            // disabled={!editor.can().liftListItem("listItem")}
          >
            <ClearIcon />
          </IconButton>
        </div>

        <div className="toolbar">
          <input
            type="color"
            value={bgHex}
            style={{ width: 60 }}
            onChange={(e) => setBgHex(e.target.value)}
          />
          <input
            type="color"
            value={textHex}
            style={{ width: 60 }}
            onChange={(e) => setTextHex(e.target.value)}
          />
          <button
            onClick={() =>
              editor.chain().focus().setColor(textHex).setBackColor(bgHex).run()
            }
            style={{ backgroundColor: bgHex, color: textHex }}
          >
            custom colors
          </button>

          <IconButton
            style={{ margin: 0, padding: 0 }}
            onClick={() => {
              editor.commands.unsetAllMarks();
              setBgHex("#ffffff");
              setTextHex("#000000");
            }}
            // disabled={!editor.can().liftListItem("listItem")}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className="toolbar">
          <IconButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            <FormatListBulletedIcon />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("orderedList") ? "is-active" : ""}
          >
            <FormatListNumberedIcon />
          </IconButton>

          <button
            onClick={() =>
              editor.chain().focus().sinkListItem("listItem").run()
            }
            disabled={!editor.can().sinkListItem("listItem")}
          >
            sink
          </button>
          <button
            onClick={() =>
              editor.chain().focus().liftListItem("listItem").run()
            }
            disabled={!editor.can().liftListItem("listItem")}
          >
            lift
          </button>
          <div
            id="DIVIDER"
            style={{
              width: "10",
              height: 40,
              backgroundColor: theme.palette.info.main,
            }}
          >
            &nbsp;
          </div>
          <IconButton
            sx={{
              ...theme.typography.button2,
              margin: 0,
              padding: 1,
              height: "normal",
            }}
            title="clean up emtpy paragraphs"
            onClick={doCleanup}
          >
            <CleaningServicesIcon />
          </IconButton>
        </div>

        <div className="toolbar">
          <button
            onClick={() => {
              editor.chain().focus().insertTable({ rows: 2, cols: 2 }).run();
              editor.chain().focus().toggleHeaderRow().run();
            }}
          >
            Insert table
          </button>

          <div
            name="table-group"
            style={{
              display: editor.can().addColumnBefore() ? "flex" : "none",
              alignItems: "start",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <button
              onClick={() => editor.chain().focus().addColumnBefore().run()}
              style={{
                display: editor.can().addColumnBefore() ? "inline" : "none",
              }}
            >
              col before
            </button>
            <button
              onClick={() => editor.chain().focus().addColumnAfter().run()}
              style={{
                display: editor.can().addColumnAfter() ? "inline" : "none",
              }}
            >
              col after
            </button>
            <button
              onClick={() => editor.chain().focus().deleteColumn().run()}
              disabled={!editor.can().deleteColumn()}
            >
              del col
            </button>
            <button
              onClick={() => editor.chain().focus().addRowBefore().run()}
              disabled={!editor.can().addRowBefore()}
            >
              row before
            </button>
            <button
              onClick={() => editor.chain().focus().addRowAfter().run()}
              disabled={!editor.can().addRowAfter()}
            >
              row after
            </button>
            <button
              onClick={() => editor.chain().focus().deleteRow().run()}
              disabled={!editor.can().deleteRow()}
            >
              delete row
            </button>
            <button
              onClick={() => editor.chain().focus().deleteTable().run()}
              disabled={!editor.can().deleteTable()}
            >
              Delete table
            </button>

            <div id="HIDDEN" style={{ display: "none" }}>
              <button
                onClick={() => editor.chain().focus().mergeCells().run()}
                disabled={!editor.can().mergeCells()}
              >
                Merge cells
              </button>
              <button
                onClick={() => editor.chain().focus().splitCell().run()}
                disabled={!editor.can().splitCell()}
              >
                Split cell
              </button>
              <button
                onClick={() =>
                  editor.chain().focus().toggleHeaderColumn().run()
                }
                disabled={!editor.can().toggleHeaderColumn()}
              >
                ToggleHeaderColumn
              </button>
              <button
                onClick={() => editor.chain().focus().toggleHeaderRow().run()}
                disabled={!editor.can().toggleHeaderRow()}
              >
                Toggle header row
              </button>
              <button
                onClick={() => editor.chain().focus().toggleHeaderCell().run()}
                disabled={!editor.can().toggleHeaderCell()}
              >
                Toggle header cell
              </button>

              <button
                onClick={() => editor.chain().focus().mergeOrSplit().run()}
                disabled={!editor.can().mergeOrSplit()}
              >
                Merge or split
              </button>
            </div>

            <div style={{ display: "flex" }}>
              <input
                list="muiColors"
                value={cellBgColor}
                onChange={(e) => setCellBgColor(e.target.value)}
                type="color"
              />
              <datalist id="muiColors">
                {Object.entries(theme.palette).map((palette, index) => {
                  if (!palette[1].main) {
                    return;
                  }
                  return (
                    <option key={index + "colors"}>{palette[1].main}</option>
                  );
                })}
                {Object.entries(theme.palette).map((palette, index) => {
                  if (!palette[1].main) {
                    return;
                  }
                  return (
                    <option key={index + "palette4"}>
                      {palette[1].contrastText}
                    </option>
                  );
                })}
              </datalist>
              <button
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .setCellAttribute("backgroundColor", cellBgColor)
                    .run()
                }
                disabled={
                  !editor.can().setCellAttribute("backgroundColor", cellBgColor)
                }
              >
                bg color
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <input
                list="muiColors"
                value={cellColor}
                onChange={(e) => setCellColor(e.target.value)}
                type="color"
              />
              <button
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .setCellAttribute("color", cellColor)
                    .run()
                }
                disabled={!editor.can().setCellAttribute("color", cellColor)}
              >
                Text Color
              </button>
            </div>
            <IconButton
              onClick={() => {
                editor.chain().focus().setCellAttribute("color", "").run();
                editor
                  .chain()
                  .focus()
                  .setCellAttribute("backgroundColor", "")
                  .run();
              }}
            >
              <ClearIcon />
            </IconButton>

            <button
              style={{ display: "none" }}
              onClick={() => editor.chain().focus().fixTables().run()}
              disabled={!editor.can().fixTables()}
            >
              Fix tables
            </button>
            <button
              style={{ display: "none" }}
              onClick={() => editor.chain().focus().goToNextCell().run()}
              disabled={!editor.can().goToNextCell()}
            >
              Go to next cell
            </button>
            <button
              style={{ display: "none" }}
              onClick={() => editor.chain().focus().goToPreviousCell().run()}
              disabled={!editor.can().goToPreviousCell()}
            >
              Go to previous cell
            </button>
          </div>
        </div>
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          // justifyContent: "center",
        }}
      >
        <ConfirmButtons label="clear content" action={clearEditor} />
        <Button
          style={{ ...theme.typography.button2 }}
          onClick={() => {
            setShowHtmlSource(false);
            toggleSourceView();
          }}
        >
          {isSourceView ? "Editor" : "Source"}
        </Button>
        <label style={{ display: isSourceView ? "block" : "none" }}>
          html
          <input
            type="checkbox"
            name="html"
            checked={showHtmlSource}
            onChange={(e) => {
              setShowHtmlSource(e.target.checked);

              // console.log(updatedHtml);
              if (e.target.checked) {
                setHtmlSource(pretty(editor.getHTML()));
              }

              if (!e.target.checked) {
                //going from html to json
                try {
                  const updatedHtml = e.currentTarget.innerText;

                  // const jsonContent = editor.schema.generateJSON(updatedHtml);
                  // setSourceContent(jsonContent);
                  // // setSourceContent(JSON.stringify(jsonContent, null, 2)); // Update JSON source
                  // editor.commands.setContent(jsonContent); // Update TipTap content
                } catch (err) {
                  console.error("Failed to parse HTML:", err);
                }
              }
            }}
          />
        </label>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            alignItems: "flex-start",
            // width: 300,
            // border: "1px solid black",
          }}
        >
          <Button
            type="submit"
            //onClick={handleSubmit}
            sx={{ ...theme.typography.button, marginRight: 1 }}
          >
            SAVE
          </Button>
          <label>
            label&nbsp;
            <InputBase
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              size="small"
              label="label"
              placeholder="label (required)"
              style={{ height: 20, width: 100, border: "1px solid black" }}
              required
            ></InputBase>
          </label>
          <label>
            title&nbsp;
            <InputBase
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              size="small"
              label="title"
              placeholder="title (optional)"
              style={{ height: 20, width: 180, border: "1px solid black" }}
            ></InputBase>
          </label>

          <label>
            postion&nbsp;
            <input
              style={{ width: 40 }}
              type="number"
              onChange={(e) => setPosition(e.target.value)}
              value={position}
            ></input>
          </label>
        </div>
      </form>

      <div
        style={{
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: 5,
          margin: 5,
          padding: 0,
        }}
      >
        {!isSourceView ? (
          <EditorContent editor={editor} />
        ) : (
          <div>
            <textarea
              style={{
                display: showHtmlSource ? "none" : "block",
                width: "100%",
                height: "400px",
              }}
              value={sourceContent}
              onChange={handleSourceChange} // Capture changes in source view
            />
            <pre
              contentEditable
              suppressContentEditableWarning
              style={{
                display: showHtmlSource ? "block" : "none",
                width: "100%",
                height: "400px",
                whiteSpace: "pre-wrap",
                overflowY: "scroll",
              }}
              onBlur={(e) => {
                const updatedHtml = e.currentTarget.innerText;
                console.log(updatedHtml);
                try {
                  // console.log(generateJSON(updatedHtml, editor.schema));
                } catch (err) {
                  console.log(err);
                }
                return;
                // setHtmlSource(updatedHtml);
                // editor.content = e.currentTarget.innerText;
                // editor.commands.setContent(
                //   // editor.schema.parseHTML(updatedHtml)
                //   editor.schema.nodeFromDOM(window.document)
                // );
              }}
              onInput={(e) => {
                // const updatedHtml = e.currentTarget.innerText;
                //  setHtmlSource(updatedHtml);
                // // console.log(updatedHtml);
                // try {
                //   const jsonContent = editor.schema.generateJSON(updatedHtml);
                //   setSourceContent(jsonContent);
                //   // setSourceContent(JSON.stringify(jsonContent, null, 2)); // Update JSON source
                //   editor.commands.setContent(jsonContent); // Update TipTap content
                // } catch (err) {
                //   console.error("Failed to parse HTML:", err);
                // }
              }}
            >
              {htmlSource}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default TipTapEditor;
