// CalendarList.js
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { Autocomplete, TextField, Button, Slider } from "@mui/material";
// import EditEvent from "../components/Events/EditEvent.js";
import AddEvent from "../components/Events/AddEvent.js";
import GetEvents from "../components/Events/GetEvents.js";
import HeaderOnly from "../components/Header/HeaderOnly.js";
import Footer from "../components/Header/Footer.js";
import "./events.css";
// import ValidateJwt from "../components/Login/ValidateJwt.js";

// import isUserValidated from "../util/isUserValidated.js";

// import { appConfig } from "../config";

import { useTheme } from "@mui/material/styles";

// import { appConfig } from "../config.js";

function Events({ trig }) {
  const theme = useTheme();
  const [showAdd, setShowAdd] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://contradances.net/api/embed.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script); // Clean up script on unmount
    };
  }, []);

  return (
    <div>
      <HeaderOnly title="Dances & events" />
    </div>
  );
}

export default Events;
