import React, { useState, useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import {
  // Box,
  // Select,
  // MenuItem,
  // Autocomplete,
  Button,
  //   Modal,
  // Slider,
  // IconButton,
  // Dialog,
  // DialogContent,
  // TextField,
  // Grid,
  // InputBase,
} from "@mui/material";
import StarterKit from "@tiptap/starter-kit";
import Typography from "@tiptap/extension-typography";
import FontSize from "tiptap-extension-font-size";
import FontFamily from "@tiptap/extension-font-family";
import CustomLink from "../Rte/CustomLink.js";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import GlobalStyleAttributes from "../Rte/GlobalStyleAttributes";
import { SmilieReplacer } from "../Rte/SmilieReplacer.ts";
import TinyToolbar from "./TinyToolbar";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import { useTheme } from "@mui/material/styles";

import { appConfig } from "../../config.js";

const TinyRte = ({ event, eventKey, eventDetailsMap, setEventDetailsMap }) => {
  const theme = useTheme();
  const [saving, setSaving] = useState(false);
  const [isSourceView, setSourceView] = useState(false);
  const [sourceContent, setSourceContent] = useState("");
  const [msg, setMsg] = useState("");

  const [mode, setMode] = useState("POST");

  const [eventDetails, setEventDetails] = useState(eventDetailsMap);

  useEffect(() => {
    console.log("useeff");
    if (eventDetails.substring(0, 1) === "<") {
      setMode("PUT");
      console.log("Mode PUT");
    }
  }, [eventDetails]);

  const doCleanup = () => {
    const cleanedContent = cleanup(editor.getJSON());
    editor.commands.setContent(cleanedContent);
  };

  function cleanup(doc) {
    if (!doc || typeof doc !== "object") return doc;

    // If the current node is a paragraph with no content, return null to indicate removal
    if (
      doc.type === "paragraph" &&
      (!doc.content || doc.content.length === 0)
    ) {
      return null;
    }

    // If the node has content, clean up its children recursively
    if (Array.isArray(doc.content)) {
      doc.content = doc.content
        .map(cleanup) // Clean each child node
        .filter(Boolean); // Remove null entries
    }

    return doc;
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      FontSize,
      FontFamily,
      CustomLink,
      Underline,
      TextStyle,
      SmilieReplacer,
      GlobalStyleAttributes,
    ],
    content: eventDetails,
  });

  const handleUpdate = async (e) => {
    if (!editor) return;

    setSaving(true);
    const content = editor.getHTML();

    try {
      const response = await fetch(`${appConfig.NODEURL}api/eventdetails`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          content: content,
          rrule_instance_date: new Date(event.start_time)
            .toISOString()
            .split(".")[0]
            .replace("T", " "),
          event_id: event.id,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update");
      }

      const newData = await response.json();

      // ✅ Update parent component's eventDetailsMap using eventKey
      setEventDetailsMap((prev) => ({
        ...prev,
        [eventKey]: newData.content, // Update specific event details
      }));

      setMsg("Updated successfully!");
    } catch (error) {
      console.error("Error updating content:", error);
      setMsg("Error updating content");
    } finally {
      setSaving(false);
    }
  };

  // const handleUpdate = async (e) => {
  //   // e.stopPropagation();
  //   if (!editor) return;

  //   setSaving(true);
  //   const content = editor.getHTML();

  //   try {
  //     const response = await fetch(`${appConfig.NODEURL}api/eventdetails`, {
  //       method: "PUT",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         content: content,
  //         rrule_instance_date: new Date(event.start_time)
  //           .toISOString()
  //           .split(".")[0]
  //           .replace("T", " "),
  //         event_id: event.id,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to save");
  //     }

  //     const newData = await response.json(); // Get the new row data]]
  //     console.log(eventDetailsMap);
  //     console.log(newData);
  //     // setEventDetails(newData.content); // Update eventDetails state
  //     // setEventDetailsMap({ newData });

  //     setMsg("Saved successfully!");
  //   } catch (error) {
  //     console.error("Error saving content:", error);
  //     setMsg("Error saving content");
  //   } finally {
  //     setSaving(false);
  //   }
  // };

  // const handleSave_old = async (e) => {
  //   // e.stopPropagation();
  //   if (!editor) return;

  //   setSaving(true);
  //   const content = editor.getHTML();

  //   const formatDateForDB = (date) =>
  //     date.toISOString().split(".")[0].replace("T", " ");

  //   const formattedDate = formatDateForDB(new Date(event.start_time));

  //   try {
  //     const response = await fetch(`${appConfig.NODEURL}api/eventdetails`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         content: content,
  //         rrule_instance_date: formattedDate,
  //         rrule_instance_ts: new Date(event.start_time).toISOString(),
  //         event_id: event.id,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to save");
  //     }

  //     // const newData = await response.json(); // Get the new row data
  //     // console.log(newData);
  //     // setEventDetails(newData.content); // Update eventDetails state
  //     // setEventDetailsMap(newData);

  //     setMsg("Saved successfully!");
  //   } catch (error) {
  //     console.error("Error saving content:", error);
  //     setMsg("Error saving content");
  //   } finally {
  //     setSaving(false);
  //   }
  // };

  const handleSave = async (e) => {
    if (!editor) return;

    setSaving(true);
    const content = editor.getHTML();
    const formattedDate = new Date(event.start_time)
      .toISOString()
      .split(".")[0]
      .replace("T", " ");

    try {
      const response = await fetch(`${appConfig.NODEURL}api/eventdetails`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          content: content,
          rrule_instance_date: formattedDate,
          event_id: event.id,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save");
      }

      const newData = await response.json();

      setMode("PUT");

      // ✅ Update parent component's eventDetailsMap using eventKey
      setEventDetailsMap((prev) => ({
        ...prev,
        [eventKey]: newData.content, // Update specific event details
      }));

      setMsg("Saved successfully!");
    } catch (error) {
      console.error("Error saving content:", error);
      setMsg("Error saving content");
    } finally {
      setSaving(false);
    }
  };

  const toggleSourceView = (e) => {
    e.stopPropagation();
    if (!editor) return;

    if (!isSourceView) {
      // Switching to source view: Save editor content as formatted JSON
      const jsonContent = editor.getJSON();
      setSourceContent(JSON.stringify(jsonContent, null, 2));
    } else {
      try {
        // Switching back to editor view: Apply JSON content
        const parsedContent = JSON.parse(sourceContent);
        editor.commands.setContent(parsedContent);
      } catch (error) {
        console.error("Invalid JSON content:", error);
        alert("Invalid JSON content. Please fix any syntax errors.");
      }
    }
    setSourceView(!isSourceView);
  };

  const clearEditor = () => {
    editor.commands.setContent("");
    setSourceContent(
      JSON.stringify(
        {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
        null,
        3
      )
    );
    //   const html = editor.getHTML();
    //   const json = editor.getJSON();
    //   const send = { html: html, json: json };
    //   dispatch(update(send));
  };
  const delDetails = () => {
    try {
      fetch(
        `${appConfig.NODEURL}api/eventdetails/?event_id=${
          event.id
        }&rrule_instance_date=${new Date(event.start_time)
          .toISOString()
          .split(".")[0]
          .replace("T", " ")}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json()) // or res.json()
        .then((res) => setMsg(res.message));

      // setEventDetails("");

      setEventDetailsMap((prev) => ({
        ...prev,
        [eventKey]: "", // Update specific event details
      }));

      clearEditor();
      setMode("POST");
    } catch (err) {
      setMsg(err);
    }
  };

  const [rand, setRand] = useState(0);

  return (
    <div>
      <div>{msg}</div>
      <TinyToolbar editor={editor} handleSave={handleSave} />
      <div style={{ display: "flex", alignItems: "center" }}>
        {mode === "POST" && (
          <Button
            sx={{ ...theme.typography.button2 }}
            onClick={async (e) => {
              handleSave(e);
            }}
            disabled={saving}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        )}

        {mode === "PUT" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              sx={{ ...theme.typography.button2 }}
              onClick={async (e) => {
                handleUpdate(e);
              }}
              disabled={saving}
            >
              Update
            </Button>
            <ConfirmButtons label="delete" action={delDetails}></ConfirmButtons>
          </div>
        )}

        <Button
          sx={{ ...theme.typography.button2 }}
          onClick={(e) => toggleSourceView(e)}
        >
          {isSourceView ? "Editor" : "JSON"}
        </Button>
        <Button
          sx={{ ...theme.typography.button2 }}
          onClick={(e) => doCleanup(e)}
        >
          clean
        </Button>
        <ConfirmButtons label="clear" action={clearEditor} />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {eventDetails === "" || eventDetails === ""}
      </div>
      {isSourceView ? (
        <textarea
          style={{ width: "100%", height: "400px", fontFamily: "monospace" }}
          value={sourceContent}
          onChange={(e) => setSourceContent(e.target.value)}
        />
      ) : (
        <div
          style={
            {
              // boxShadow: theme.shadows[19],
              // border: "2px solid black",
              // height: 200,
            }
          }
        >
          <label>Editor:</label>
          <EditorContent
            style={{ border: "1px solid black", zIndex: 5000 }}
            onClick={(e) => e.stopPropagation()}
            editor={editor}
          />
        </div>
      )}
    </div>
  );
};

export default TinyRte;
