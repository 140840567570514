import React, { useState, useEffect, useRef } from "react";
import { Button, Dialog, Zoom, Box, Checkbox, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../../config.js";
import isUserValidated from "../../util/isUserValidated.js";
import TinyRte from "./TinyRte.js";
import CloseIcon from "@mui/icons-material/Close";

const EventBox = ({ row, exportEvents, setExportEvents }) => {
  const theme = useTheme();
  delete row.geocode;
  delete row.image;
  delete row.isOccurrence;

  const [showDetails, setShowDetails] = useState(false);
  // const [eventDetails, setEventDetails] = useState("");

  const [eventDetailsMap, setEventDetailsMap] = useState({});

  const [debug, setDebug] = useState("");
  const [detailURL, setDetailURL] = useState("");

  const ref = useRef(null);

  const fetchedEvents = useRef(new Set());

  // const formatDateForDB = (date) =>
  //   date.toISOString().split(".")[0].replace("T", " ");

  const isRowExported = exportEvents.some((event) => event.id === row.id);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      // Add the row to exportEvents
      setExportEvents((prev) => [...prev, row]);
    } else {
      // Remove the row from exportEvents by filtering it out (using its unique id)
      setExportEvents((prev) => prev.filter((event) => event.id !== row.id));
    }
  };

  useEffect(() => {
    // console.log(`EventBox Mounted: row.id=${row.id}`);
    setDetailURL(
      `${appConfig.NODEURL}api/eventdetails/?event_id=${
        row.id
      }&rrule_instance_date=${new Date(row.start_time)
        .toISOString()
        .split(".")[0]
        .replace("T", " ")}`
    );
  }, [row.id]);

  useEffect(() => {
    // setEventDetails("");
    // setDetailURL(
    //   `${appConfig.NODEURL}api/eventdetails/?event_id=${
    //     row.id
    //   }&rrule_instance_date=${new Date(row.start_time)
    //     .toISOString()
    //     .split(".")[0]
    //     .replace("T", " ")}`
    // );
    // setDebug(detailURL);
  }, []);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     async ([entry]) => {
  //       if (entry.isIntersecting) {
  //         const eventKey = `${row.id}-${row.rrule_instance_date}`;

  //         // 🛑 Skip fetching if details are already stored for this instance
  //         if (eventDetailsMap[eventKey]) {
  //           // console.log("Already fetched event details for:", eventKey);
  //           return;
  //         }

  //         console.log("Fetching details for:", eventKey);

  //         try {
  //           const response = await fetch(
  //             `${appConfig.NODEURL}api/eventdetails/?event_id=${
  //               row.id
  //             }&rrule_instance_date=${new Date(row.start_time)
  //               .toISOString()
  //               .split(".")[0]
  //               .replace("T", " ")}`
  //           );

  //           const details = await response.json();

  //           // ✅ Store details for this specific event instance
  //           setEventDetailsMap((prev) => ({
  //             ...prev,
  //             [eventKey]: details?.content || "",
  //           }));
  //         } catch (err) {
  //           console.error("Failed to fetch event details", err);
  //         }
  //       }
  //     },
  //     { threshold: 0.1 }
  //   );

  //   if (ref.current) observer.observe(ref.current);

  //   return () => {
  //     if (ref.current) observer.unobserve(ref.current);
  //   };
  // }, [row.id, row.rrule_instance_date, eventDetailsMap]); // Depend on eventDetailsMap

  // const fetchedEvents = useRef(new Set());

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting) {
          const eventKey = `${row.id}-${row.rrule_instance_date}`;

          // ✅ Skip fetch if already fetched
          if (fetchedEvents.current.has(eventKey)) {
            // console.log("Skipping duplicate fetch:", eventKey);
            return;
          }

          // console.log("Fetching details for:", eventKey);

          try {
            const response = await fetch(
              `${appConfig.NODEURL}api/eventdetails/?event_id=${
                row.id
              }&rrule_instance_date=${new Date(row.start_time)
                .toISOString()
                .split(".")[0]
                .replace("T", " ")}`
            );

            const details = await response.json();

            // ✅ Store in eventDetailsMap
            setEventDetailsMap((prev) => ({
              ...prev,
              [eventKey]: details?.content || "",
            }));

            // ✅ Mark as fetched
            fetchedEvents.current.add(eventKey);
          } catch (err) {
            console.error("Failed to fetch event details", err);
          }
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [row.id, row.rrule_instance_date]); // ✅ Removed eventDetailsMap dependency

  return (
    <Zoom in={true}>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2, // Using spacing units instead of raw numbers
          flexWrap: "wrap",
          margin: "auto",
          width: {
            xs: "100%",
            sm: "calc(50% - 16px)",
            md: "calc(33.333% - 16px)",
          },
          minWidth: 280,
          maxWidth: 440,
          backgroundColor: theme.palette.grey[100],
          borderRadius: 2,
          boxShadow: theme.shadows[1],
        }}
      >
        <Box>
          <Box sx={{ ...theme.typography.h6, fontWeight: 1000 }}>
            {row.name}
          </Box>
          <Box
            sx={{
              ...theme.typography.h6,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              borderRadius: 1,
              p: 1, // Padding for better appearance
            }}
          >
            {new Date(row.start_time).toDateString()}
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          {row.image_url && (
            <img
              onClick={(e) => (e.target.style.width = "")}
              onMouseLeave={(e) => (e.target.style.width = "160px")}
              style={{ float: "left", width: 160 }}
              src={row.image_url}
            />
          )}
          {row.body && row.body}
          {row.web_url && (
            <Box>
              <a target="_new" style={{ display: "block" }} href={row.web_url}>
                {row.link_label
                  ? row.link_label
                  : row.web_url.replace("https://", "")}
              </a>
              {row.link_description}
            </Box>
          )}
        </Box>

        {row.formatted_address && <Box>{row.formatted_address}</Box>}
        <Box>
          {`${(row.distance / 1000).toFixed(2)}/km ${(
            (row.distance * 0.621371) /
            1000
          ).toFixed(2)}/miles`}
        </Box>
        <Box
          dangerouslySetInnerHTML={{
            __html:
              eventDetailsMap[`${row.id}-${row.rrule_instance_date}`] || "",
          }}
        />

        <Box sx={{ display: isUserValidated() ? "block" : "none" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{ ...theme.typography.button2 }}
              onClick={(e) => {
                e.preventDefault();
                setShowDetails((prev) => !prev);
              }}
              href="#"
            >
              {showDetails ? <CloseIcon /> : "manage details"}
            </Button>
            <InputLabel>
              copy
              <Checkbox
                checked={isRowExported}
                onChange={handleCheckboxChange}
                // sx={{ ...theme.typography.button2 }}
                // onClick={(e) => {
                //   console.log(row);
                //   // e.preventDefault();
                //   // setShowDetails((prev) => !prev);
                // }}
                href="#"
              />
            </InputLabel>
          </div>
          {showDetails && (
            <Box
              sx={{
                position: "absolute",
                zIndex: theme.zIndex.tooltip,
                backgroundColor: "#ffffff",
                p: 2,
                boxShadow: 3,
                borderRadius: 1,
              }}
            >
              <Dialog
                open={showDetails}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Button
                  sx={{ ...theme.typography.button2 }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDetails((prev) => !prev);
                  }}
                  href="#"
                >
                  {showDetails ? <CloseIcon /> : "Editx"}
                </Button>

                <TinyRte
                  eventKey={`${row.id}-${row.rrule_instance_date}`}
                  setEventDetailsMap={setEventDetailsMap}
                  eventDetailsMap={
                    eventDetailsMap[`${row.id}-${row.rrule_instance_date}`] ===
                    "no details"
                      ? ""
                      : eventDetailsMap[`${row.id}-${row.rrule_instance_date}`]
                  }
                  event={row}
                />
              </Dialog>
            </Box>
          )}
        </Box>
      </Box>
    </Zoom>
  );
};

export default EventBox;
