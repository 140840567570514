import React, { useState } from "react";
import dayjs from "dayjs";
import {
  Autocomplete,
  TextField,
  Button,
  Dialog,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../../config";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import "./event.css";

import ImageDialog from "../widgets/ImageDialog";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import RRuleBuilder from "./RRuleBuilder";
// import RRuleInput from "./RRuleInput";

function CommonInputs({
  event,
  setEvent,
  width = 0,
  height = 0,
  setWidth,
  setHeight,
}) {
  const theme = useTheme();
  const [rruleDates, setRruleDates] = useState("");
  const [showRrule, setShowRrule] = useState(false);
  const [showBody, setShowBody] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [showLinkDescription, setShowLinkDescription] = useState(false);
  // const [startDateTime, setStartDateTime] = useState("");
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEvent((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;
  //   setEvent((prev) => ({
  //     ...prev,
  //     [name]: files[0],
  //   }));
  // };

  const rruleTest = async () => {
    console.log(event.rrule);
    // fetch(`${appConfig.NODEURL}api/rrule`,)
    try {
      const response = await axios.post(
        `${appConfig.NODEURL}api/rrule`,
        { rule: event.rrule },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setRruleDates(response.data.dates);
    } catch (error) {
      console.log(error);
    }
  };

  const geoCode = () => {
    // If ZIP is not provided, don't do anything.
    if (!event.zip) return;

    const geoUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${
      event.street ? event.street : ""
    },${event.city ? event.city : ""},${event.state ? event.state : ""},${
      event.zip
    }&key=${appConfig.GmapKey}`;

    fetch(geoUrl)
      .then((response) => response.json())
      .then((data) => {
        const form = { ...event };
        const lat = data.results[0].geometry.location.lat;
        const lng = data.results[0].geometry.location.lng;
        form.lat = lat;
        form.lng = lng;
        form.geocode = JSON.stringify(data.results[0]);
        form.formatted_address = data.results[0].formatted_address;
        // console.log(data.results[0].formatted_address);
        setEvent(form);
      })
      .catch((err) => {
        // Handle error if needed.
      });
    console.log(geoUrl);
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    return new Date(dateString).toISOString().slice(0, 16);
  };

  // This component handles the image URL input and dimension controls.

  // const [width, setWidth] = useState(150);
  // const [height, setHeight] = useState(100);
  const [aspectRatio, setAspectRatio] = useState(width / height);

  const setImageUrl = (prop) => {
    const form = { ...event };
    form.image_url = appConfig.HOME + "uploads/" + prop;
    setEvent(form);
  };
  //               React.memo(() => {
  // const ImageUrl = React.memo(() => {
  //   const setDimensions = (e) => {
  //     const value = parseInt(e.target.value, 10);
  //     console.log(value);
  //     if (e.target.name === "image_width") {
  //       setWidth(value);
  //       setHeight(Math.round(value / aspectRatio));
  //       const setDimensions = (e) => {
  //         const value = parseInt(e.target.value, 10);
  //         console.log(value);
  //         if (e.target.name === "image_width") {
  //           setWidth(value);
  //           setHeight(Math.round(value / aspectRatio));
  //           setEvent((prev) => ({
  //             ...prev,
  //             image_height: Math.round(value * aspectRatio),
  //             image_width: value,
  //           }));
  //         } else if (e.target.name === "image_height") {
  //           setHeight(value);
  //           setWidth(Math.round(value * aspectRatio));
  //           setEvent((prev) => ({
  //             ...prev,
  //             image_width: Math.round(value * aspectRatio),
  //             image_height: value,
  //           }));
  //         }
  //       };

  //       // const validateDimension = (e) => {
  //       //   const value = parseInt(e.target.value, 10);
  //       //   if (e.target.name === "width" && value < 100) {
  //       //     setWidth(100);
  //       //     setHeight(Math.round(100 / aspectRatio));
  //       //   } else if (e.target.name === "height" && value < 80) {
  //       //     setHeight(80);
  //       //     setWidth(Math.round(80 * aspectRatio));
  //       //   }
  //       // };
  //     } else if (e.target.name === "image_height") {
  //       setHeight(value);
  //       setWidth(Math.round(value * aspectRatio));
  //       setEvent((prev) => ({
  //         ...prev,
  //         image_width: Math.round(value * aspectRatio),
  //         image_height: value,
  //       }));
  //     }
  //   };

  //   const validateDimension = (e) => {
  //     const value = parseInt(e.target.value, 10);
  //     if (e.target.name === "width" && value < 100) {
  //       setWidth(100);
  //       setHeight(Math.round(100 / aspectRatio));
  //     } else if (e.target.name === "height" && value < 80) {
  //       setHeight(80);
  //       setWidth(Math.round(80 * aspectRatio));
  //     }
  //   };

  //   return (
  //     <div>
  //       <ImageDialog
  //         setHeight={setHeight}
  //         setWidth={setWidth}
  //         setAspectRatio={setAspectRatio}
  //         setImageUrl={setImageUrl}
  //         setEvent={setEvent}
  //       />
  //       <label>
  //         w
  //         <TextField size="small"
  //           min="100"
  //           style={{ width: 55 }}
  //           type="number"
  //           name="image_width"
  //           value={event.image_width}
  //           onChange={setDimensions}
  //           onBlur={validateDimension}
  //         />
  //       </label>
  //       <label>
  //         h
  //         <TextField size="small"
  //           min="80"
  //           style={{ width: 55 }}
  //           type="number"
  //           name="image_height"
  //           value={event.image_height}
  //           onChange={setDimensions}
  //           onBlur={validateDimension}
  //         />
  //       </label>
  //     </div>
  //   );
  // });

  // const RRuleInput = () => {
  //   const [show, setShow] = useState(false);

  //   if (!show) {
  //     return (
  //       <label>
  //         Recurring
  //         <TextField size="small"
  //           type="checkbox"
  //           onChange={(e) => {
  //             setShow(true);
  //           }}
  //         />
  //       </label>
  //     );
  //   }
  //   return (
  //     <div>
  //       <label>
  //         Recurring
  //         <TextField size="small"
  //           type="checkbox"
  //           checked
  //           onChange={(e) => {
  //             setShow(false);
  //           }}
  //         />
  //       </label>

  //       <label
  //         style={{
  //           backgroundColor: theme.palette.grey[300],
  //           padding: 5,
  //           borderRadius: 5,
  //         }}
  //       >
  //         RRule:
  //         <TextField size="small"
  //           type="text"
  //           name="rrule"
  //           value={event.rrule}
  //           onChange={handleChange}
  //         />
  //       </label>
  //     </div>
  //   );
  // };

  const setDimensions = (e) => {
    const value = parseInt(e.target.value, 10);
    console.log(value);
    if (e.target.name === "image_width") {
      setWidth(value);
      setHeight(Math.round(value / aspectRatio));
      setEvent((prev) => ({
        ...prev,
        image_height: Math.round(value * aspectRatio),
        image_width: value,
      }));
    } else if (e.target.name === "image_height") {
      setHeight(value);
      setWidth(Math.round(value * aspectRatio));
      setEvent((prev) => ({
        ...prev,
        image_width: Math.round(value * aspectRatio),
        image_height: value,
      }));
    }
  };

  const validateDimension = (e) => {
    const value = parseInt(e.target.value, 10);
    if (e.target.name === "width" && value < 100) {
      setWidth(100);
      setHeight(Math.round(100 / aspectRatio));
    } else if (e.target.name === "height" && value < 80) {
      setHeight(80);
      setWidth(Math.round(80 * aspectRatio));
    }
  };

  return (
    <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
      {/* NAME (text, required) */}
      {/* <pre>{JSON.stringify(event, null, 3)}</pre> */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        NAME:
        <TextField
          size="small"
          type="text"
          name="name"
          value={event.name || ""}
          required
          onChange={handleChange}
        />
      </label>

      {/* START TIME (datetime-local) */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <label
          style={{
            display: "flex",
            gap: 3,
            alignItems: "flex-start",
            margin: "5px 0",
          }}
        >
          START TIME: */}
        {/* <TextField size="small" 
            type="datetime-local"
            name="start_time"
            value={formatDateForInput(event.start_time)}
            onChange={handleChange}
          /> */}

        <DemoContainer components={["DateTimePicker"]}>
          <DateTimePicker
            size="small"
            name="start_time"
            value={event.start_time ? dayjs(event.start_time) : dayjs()}
            onChange={(newValue) => {
              // setStartDateTime(newValue);
              setEvent((prev) => ({
                ...prev,
                start_time: newValue.toISOString(),
              }));
              // console.log(newValue);
            }}
            label="start time"
          />
        </DemoContainer>
        {/* </label> */}

        {/* END TIME (datetime-local) */}
        <label
          style={{
            display: "none",
            gap: 3,
            alignItems: "flex-start",
            margin: "5px 0",
          }}
        >
          END TIME:
          <TextField
            size="small"
            type="datetime-local"
            name="end_time"
            value={formatDateForInput(new Date().toISOString())}
            onChange={handleChange}
          />
        </label>
      </LocalizationProvider>
      {/* <RRuleInput event={event} handleChange={handleChange} /> */}
      <div style={{ display: "flex" }}>
        <label
          stye={{ display: "flex", flexWrap: "nowrap", position: "relative" }}
        >
          <Button
            sx={{ ...theme.typography.button }}
            onClick={(e) => {
              e.preventDefault();
              setShowRrule((prev) => !prev);
            }}
            href="#"
          >
            RRule
          </Button>
          <Dialog
            onKeyUp={(e) => {
              setShowRrule(e.code === "Escape" ? false : true);
              console.log(e.code);
            }}
            open={showRrule}
            style={{
              borderRadius: 5,
            }}
          >
            <div style={{ display: "flex" }}>
              <IconButton onClick={() => setShowRrule(false)}>
                <CloseIcon />
              </IconButton>
              <div style={{ ...theme.typography.h5 }}>Reccurrence Rule</div>

              <Button
                onClick={() => setRruleDates("")}
                style={{
                  ...theme.typography.button,
                  display: rruleDates === "" ? "none" : "inline",
                  // position: "absolute",
                  zIndex: theme.zIndex.tooltip + 1000,
                  right: 0,
                  top: 0,
                }}
              >
                clear
              </Button>
            </div>
            <TextField
              size="small"
              style={{ width: 250 }}
              type="text"
              name="rrule"
              value={event.rrule ? event.rrule : ""}
              onChange={handleChange}
            />
            <Button
              onClick={rruleTest}
              disabled={event.rrule === "" ? true : false}
              sx={{ ...theme.typography.button2 }}
            >
              test
            </Button>
            <pre style={{ display: rruleDates === "" ? "none" : "inline" }}>
              {JSON.stringify(rruleDates, null, 3)}
            </pre>
          </Dialog>
        </label>

        {/* BODY (textarea) */}
        <label>
          <Button
            sx={{ ...theme.typography.button }}
            onClick={(e) => {
              e.preventDefault();
              setShowBody((prev) => !prev);
            }}
            href="#"
          >
            Body
          </Button>
          <Dialog
            onKeyUp={(e) => {
              setShowBody(e.code === "Escape" ? false : true);
              console.log(e.code);
            }}
            open={showBody}
            style={{
              borderRadius: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 3,
                alignItems: "flex-start",
                margin: "5px 0",
                position: "relative",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <IconButton onClick={() => setShowBody(false)}>
                    <CloseIcon />
                  </IconButton>
                  <div style={{ ...theme.typography.h5 }}>Body</div>
                </div>
                <textarea
                  name="body"
                  value={event.body || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Dialog>
        </label>

        {/* NOTE (text) */}

        <label>
          <Button
            sx={{ ...theme.typography.button }}
            onClick={(e) => {
              e.preventDefault();
              setShowNote((prev) => !prev);
            }}
            href="#"
          >
            Note
          </Button>

          <Dialog
            onKeyUp={(e) => {
              setShowNote(e.code === "Escape" ? false : true);
              // console.log(e.code);
            }}
            open={showNote}
            style={{
              borderRadius: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 3,
                alignItems: "flex-start",
                margin: "5px 0",
                position: "relative",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <IconButton onClick={() => setShowNote(false)}>
                    <CloseIcon />
                  </IconButton>
                  <div style={{ ...theme.typography.h5 }}>Note</div>
                </div>
                <textarea
                  name="note"
                  value={event.note || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Dialog>
        </label>
      </div>

      {/* EMAIL (email) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        EMAIL:
        <TextField
          size="small"
          type="email"
          name="email"
          value={event.email || ""}
          onChange={handleChange}
        />
      </label>

      {/* STREET (address, onBlur geoCode) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        STREET:
        <TextField
          size="small"
          type="address"
          name="street"
          value={event.street || ""}
          onChange={handleChange}
          onBlur={geoCode}
        />
      </label>

      {/* CITY (address, onBlur geoCode) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        CITY:
        <TextField
          size="small"
          type="address"
          name="city"
          value={event.city || ""}
          onChange={handleChange}
          onBlur={geoCode}
        />
      </label>

      {/* STATE (address, maxLength 2, onBlur geoCode) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        STATE:
        <TextField
          size="small"
          type="address"
          name="state"
          value={event.state || ""}
          onChange={handleChange}
          onBlur={geoCode}
          maxLength={2}
        />
      </label>

      {/* ZIP (address, maxLength 10, onBlur geoCode) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        ZIP:
        <TextField
          size="small"
          type="address"
          name="zip"
          value={event.zip || ""}
          onChange={handleChange}
          onBlur={geoCode}
          maxLength={10}
        />
      </label>

      {/* IMAGE URL (img_url with extra ImageUrl component) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        IMAGE URL:
        <div>
          <TextField
            size="small"
            type="url"
            name="image_url"
            value={event.image_url || ""}
            onChange={handleChange}
          />
          {/* <ImageUrl /> */}
          <div>
            <ImageDialog
              setHeight={setHeight}
              setWidth={setWidth}
              event={event}
              setEvent={setEvent}
              setAspectRatio={setAspectRatio}
              setImageUrl={setImageUrl}
            />
            <label>
              w
              <TextField
                size="small"
                // min="100"
                style={{ width: 55 }}
                type="number"
                name="image_width"
                // value={event.image_width}
                value={width}
                onChange={setDimensions}
                onBlur={validateDimension}
              />
            </label>
            <label>
              h
              <TextField
                size="small"
                // min="80"
                style={{ width: 55 }}
                type="number"
                name="image_height"
                // value={event.image_height}
                value={height}
                onChange={setDimensions}
                onBlur={validateDimension}
              />
            </label>
          </div>
        </div>
      </label>

      {/* WEB URL (url) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        WEB URL:
        <TextField
          size="small"
          type="url"
          name="web_url"
          value={event.web_url || ""}
          onChange={handleChange}
        />
      </label>

      {/* LINK LABEL (text) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        LINK LABEL:
        <TextField
          size="small"
          type="text"
          name="link_label"
          value={event.link_label || ""}
          onChange={handleChange}
        />
      </label>

      {/* LINK DESCRIPTION (textarea) */}

      <label>
        <Button
          sx={{ ...theme.typography.button }}
          onClick={(e) => {
            e.preventDefault();
            setShowLinkDescription((prev) => !prev);
          }}
          href="#"
        >
          link description
        </Button>
        <Dialog
          onKeyUp={(e) => {
            setShowLinkDescription(e.code === "Escape" ? false : true);
            console.log(e.code);
          }}
          open={showLinkDescription}
          style={{
            borderRadius: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 3,
              alignItems: "flex-start",
              margin: "5px 0",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <IconButton onClick={() => setShowLinkDescription(false)}>
                  <CloseIcon />
                </IconButton>
                <div style={{ ...theme.typography.h5 }}>Link Description</div>
              </div>

              <textarea
                name="link_description"
                value={event.link_description || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </Dialog>
        {/* <div
          style={{
            display: "flex",
            gap: 3,
            alignItems: "flex-start",
            margin: "5px 0",
            position: "relative",
          }}
        >
        
            <textarea
              name="link_description"
              value={event.link_description || ""}
              onChange={handleChange}
            />
        </div> */}
      </label>

      {/* TYPE (select via Autocomplete) */}
      <label
        style={{
          display: "flex",
          gap: 3,
          alignItems: "flex-start",
          margin: "5px 0",
        }}
      >
        Type
        <div>
          <Autocomplete
            size="small"
            disablePortal
            options={["regular", "weekend", "special"]}
            value={event.type || ""}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                size="small"
                size="small"
                {...params}
                name="type"
                value={event.type || ""}
                onChange={handleChange}
                onClick={handleChange}
              />
            )}
          />
        </div>
      </label>
      <div>
        {event.formatted_address ? (
          <label style={{ display: "flex", flexDirection: "column" }}>
            Formatted Address:
            <TextField size="small" disabled value={event.formatted_address} />
          </label>
        ) : (
          ""
        )}
      </div>
      <div>
        {/* LAT (geo, disabled) */}
        <label
          style={{
            display: "flex",
            gap: 3,
            alignItems: "flex-start",
            margin: "5px 0",
          }}
        >
          LAT:
          <TextField
            size="small"
            style={{ width: 90 }}
            disabled
            value={event.lat || ""}
          />
        </label>

        {/* LNG (geo, disabled) */}
        <label
          style={{
            display: "flex",
            gap: 3,
            alignItems: "flex-start",
            margin: "5px 0",
          }}
        >
          LNG:
          <TextField
            size="small"
            style={{ width: 90 }}
            disabled
            value={event.lng || ""}
          />
        </label>
      </div>
      {/* <RRuleBuilder /> */}
      {/* <pre>{JSON.stringify(event, null, 3)}</pre> */}
    </div>
  );
}

export default CommonInputs;
