import React, { useState, useEffect } from "react";
import {
  // Box,
  // Select,
  // MenuItem,
  // Autocomplete,
  Button,
  IconButton,
  Zoom,
  // Dialog,
  // DialogContent,
  // TextField,
  // Grid,
  // InputBase,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CommonInputs from "./CommonInputs";
import { appConfig } from "../../config.js";
import { ClockIcon } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
// import { WidthFull } from "@mui/icons-material";

function AddEvent({
  setSelectedEvent,
  fetchEvents,
  showAdd,
  setShowAdd,
  setEventId,
}) {
  const theme = useTheme();
  const initEvent = {
    end_time: new Date(), // input hidden, with rrule, end_time is not useful
    start_time: new Date(),
    lat: "0",
    lng: "0",
    type: "regular",
    rrule: "FREQ=DAILY;COUNT=1",
  };
  const [event, setEvent] = useState(initEvent);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const ev = { ...event };
    ev.image_height = height;
    ev.image_width = width;
    setEvent(ev);
    // setEvent((prev) => ({ ...prev, height: height, width: width }));
  }, [width, height]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(event);
    delete event.command;
    // event.image_width = width;
    // event.image_height = height;
    try {
      delete event.recurring;
      const response = await fetch(`${appConfig.NODEURL}api/events`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(event),
      });

      if (!response.ok) {
        throw new Error("Failed to add dance");
      }

      const data = await response.json();
      console.log("Event added:", data);
      // setEventId(data.id);

      // Reset form after submission
      setEvent(data);
      setShowAdd(false);
      fetchEvents();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  if (!showAdd) {
    return (
      <div>
        <Button
          style={{ ...theme.typography.button }}
          onClick={() => {
            setShowAdd(true);
            setSelectedEvent({ name: "select event to edit..." });
          }}
        >
          Add Dance
        </Button>
      </div>
    );
  }
  return (
    <Zoom in={true}>
      <div
        style={{
          boxShadow: theme.shadows[12],
          borderRadius: 10,
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex" }}>
            <IconButton
              // sx={{ ...theme.typography.button2 }}
              onClick={() => setShowAdd(false)}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ ...theme.typography.h4 }}>Add Dance</div>
          </div>
          <CommonInputs
            event={event}
            setEvent={setEvent}
            height={height}
            width={width}
            setHeight={setHeight}
            setWidth={setWidth}
          />
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <Button sx={{ ...theme.typography.button }} type="submit">
              Submit Dance
            </Button>
          </div>
          {/* <pre>{JSON.stringify(event, null, 3)}</pre> */}
        </form>
      </div>
    </Zoom>
  );
}

export default AddEvent;
