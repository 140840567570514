import React from "react";
import { IconButton, Select, MenuItem } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import webSafeFonts from "../Rte/webSafeFonts"; // Ensure this is correctly imported

const TinyToolbar = ({ editor }) => {
  if (!editor) return null;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        width: "100%",
        gap: 2,
        // padding: 8,
        background: "#f5f5f5",
        position: "relative", // Ensures toolbar is properly positioned
        zIndex: 1000, // Ensure toolbar is above editor
      }}
    >
      {/* Bold */}
      <IconButton onClick={() => editor.chain().focus().toggleBold().run()}>
        <FormatBoldIcon />
      </IconButton>
      {/* Italic */}
      <IconButton onClick={() => editor.chain().focus().toggleItalic().run()}>
        <FormatItalicIcon />
      </IconButton>
      {/* Underline */}
      <IconButton
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <FormatUnderlinedIcon />
      </IconButton>
      {/* Bullet List */}
      <IconButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <FormatListBulletedIcon />
      </IconButton>
      {/* Ordered List */}
      <IconButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <FormatListNumberedIcon />
      </IconButton>
      {/* Font Size Selector */}
      <select
        size="small"
        defaultValue="16px"
        // MenuProps={{
        //   container: document.body, // Forces rendering to the top-level body
        //   PaperProps: {
        //     style: { zIndex: 2000 }, // Ensures dropdown renders above everything
        //   },
        // }}
        onChange={(e) =>
          editor.chain().focus().setFontSize(e.target.value).run()
        }
      >
        <option>font size...</option>
        {[12, 14, 16, 18, 20, 24, 28, 32].map((size) => (
          <option key={size} value={`${size}px`}>
            {size}px
          </option>
        ))}
      </select>
      {/* Font Family Selector */}
      <select
        style={{ width: 100 }}
        // defaultValue="Arial"
        // MenuProps={{
        //   container: document.body, // Forces rendering to the top-level body
        //   PaperProps: {
        //     style: { zIndex: 2000 }, // Ensures dropdown renders above everything
        //   },
        // }}
        onChange={(e) =>
          editor.chain().focus().setFontFamily(e.target.value).run()
        }
      >
        <option>font family...</option>
        {webSafeFonts.map((font, index) => (
          <option key={index} value={font}>
            {font}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TinyToolbar;
