import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Button,
  Box,
  // Typography,
  IconButton,
  // Dialog,
  // DialogContent,
  InputLabel,
  TextField,
  // Grid,
  // InputBase,
  Radio,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

import { addMonths, format, set } from "date-fns";

// import CommonInputs from "./CommonInputs";
import { appConfig } from "../../config.js";
import isUserValidated from "../../util/isUserValidated.js";
import EventBox from "./EventBox.js";
// import TinyRte from "./TinyRte.js";
const GetEvents = () => {
  // const id = useId();
  const theme = useTheme();
  const [events, setEvents] = useState([]);
  const [fields, setFields] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [kilometers, setKilometers] = useState(250);
  const [debouncedKilometers, setDebouncedKilometers] = useState(kilometers);
  const [zip, setZip] = useState(
    localStorage.getItem("searchZipCode") || appConfig.Zip
  );
  const [lat, setLat] = useState(localStorage.getItem("lat") || 0);
  const [lng, setLng] = useState(localStorage.getItem("lng") || 0);
  const [disableGet, setDisableGet] = useState(true);
  const [nmonths, setNmonths] = useState(1);

  const [showExport, setShowExport] = useState(false);

  const [sortBy, setSortBy] = useState("start_time");

  const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 30);
  const formattedEndDate = defaultEndDate.toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(formattedEndDate);
  const [delta, setDelta] = useState(30);

  const [copied, setCopied] = useState(false);

  const [exportEvents, setExportEvents] = useState([]);

  const zipRegex = /^\d{5}$/;

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    geoCode();
  }, [zip]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedKilometers(kilometers);
    }, 500); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [kilometers]);

  useEffect(() => {
    fetchExpanedEvents();
    setSortBy("start_time");
  }, [debouncedKilometers, startDate, endDate, lat, lng]);

  useEffect(() => {
    if (zipRegex.test(zip)) {
      localStorage.setItem("searchZipCode", zip);
    }
  }, [zip]);

  if (navigator.geolocation) {
    // navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  } else {
    // Geolocation is not supported by this browser
    console.log("Geolocation is not supported by this browser.");
  }

  useEffect(() => {
    // console.log(exSet);
    const newSet = [...exSet];
    // console.log(newSet);
    setExSet(
      newSet.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      })
    );
  }, [sortBy]);

  useEffect(() => {
    // Ensure endDate is always after startDate
    const start = new Date(startDate);
    const newEndDate = new Date(start);
    newEndDate.setDate(start.getDate() + delta);

    setEndDate(newEndDate.toISOString().split("T")[0]);
  }, [startDate]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    const start = new Date(newStartDate);
    const oldStart = new Date(startDate);
    const oldEnd = new Date(endDate);

    const newDelta = Math.round((oldEnd - oldStart) / (1000 * 60 * 60 * 24));
    setDelta(newDelta);
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate);
      setDelta(
        Math.round(
          (new Date(newEndDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
        )
      );
      setMsg("");
    } else {
      setMsg("End date must be after start date.");
    }
  };

  const geoCode = () => {
    if (!zipRegex.test(zip)) {
      console.log("Invalid ZIP code. Must be 5 digits.");
      return;
    }

    const geoUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${appConfig.GmapKey}`;
    // console.log(geoUrl);
    fetch(geoUrl)
      .then((response) => response.json())
      .then((data) => {
        // const form = { ...event };
        const lt = data.results[0].geometry.location.lat;
        const lg = data.results[0].geometry.location.lng;
        setLng(lg);
        setLat(lt);
        localStorage.setItem("lat", lat);
        localStorage.setItem("lng", lng);

        setDisableGet(false);

        // fetchExpanedEvents();
      })
      .catch((err) => {
        // Handle error if needed.
      });
    // console.log(geoUrl);
  };

  const [exSet, setExSet] = useState("");
  const [msg, setMsg] = useState("");

  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    setHighlight(true);
    const timeout = setTimeout(() => setHighlight(false), 250);
    return () => clearTimeout(timeout);
  }, [exSet]);

  const fetchExpanedEvents = () => {
    if (!zipRegex.test(zip)) {
      return;
    }

    const endISO = addDays(startDate, 30 * nmonths).toISOString();
    const dynamicApiUrl = `${appConfig.NODEURL}api/expanded-events?start=${startDate}&end=${endDate}&lat=${lat}&lng=${lng}&kilometers=${kilometers}`;

    fetch(dynamicApiUrl, { cache: "no-store" })
      .then((res) => res.json())
      .then((data) => {
        setExSet(data);
      })
      .catch((err) => console.error("Error fetching events:", err));
  };

  useEffect(() => {
    fetchExpanedEvents();
  }, []);

  useEffect(() => {
    if (lat !== 0 && lng !== 0) {
      fetchExpanedEvents();
    }
  }, [lat, lng]); // ✅ Runs only when lat/lng are set

  useEffect(() => {
    fetchExpanedEvents();
  }, [endDate]);

  useEffect(() => {
    geoCode();
  }, [zip]);

  useEffect(() => {
    fetchExpanedEvents();
  }, [lat, lng]);

  const apiUrl = `${appConfig.NODEURL}api/expanded-events?start=${startDate}&end=${endDate}&lat=${lat}&lng=${lng}&kilometers=${kilometers}`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(apiUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      setMsg("Failed to copy URL");
    }
  };

  // const closePaste = () => {
  //   setShowExport(false);
  // };

  const doPaste = async () => {
    // setShowExport(false);
    try {
      const response = await fetch(`${appConfig.NODEURL}api/eventcopy`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(exportEvents),
      });

      if (!response.ok) {
        throw new Error("Failed to add dance");
      }

      const data = await response.json();
      //console.log("Events added:", data);
      // setEventId(data.id);
      // Reset form after submission
      // setEvent(data);
      // setShowAdd(false);
      // fetchEvents();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  return (
    <div>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          gap: 10,
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "nowwrap" }}
        >
          <Button
            disabled={disableGet}
            sx={{ ...theme.typography.button, display: "none" }}
            onClick={fetchExpanedEvents}
          >
            find dances
          </Button>
          <motion.div
            animate={{
              scale: highlight ? 1.2 : 1,
              opacity: highlight ? 1 : 0.8,
            }}
            transition={{ duration: 0.3 }}
            style={{ display: "inline-block", fontSize: "24px" }}
          >
            <label
              style={{
                ...theme.typography.h5,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderRadius: 5,
                padding: 3,
              }}
            >
              found {exSet.length} dances
            </label>
          </motion.div>
        </div>

        <InputLabel style={{ display: "flex", alignItems: "center" }}>
          Zip code
          <TextField
            size="small"
            style={{ width: 80 }}
            type="text"
            onChange={(e) => setZip(e.target.value)}
            value={zip}
            // onBlur={geoCode}
            // onFocus={() => setDisableGet(true)}
          />
        </InputLabel>
        <InputLabel style={{ display: "flex", alignItems: "center" }}>
          km
          <TextField
            size="small"
            style={{ width: 90 }}
            type="number"
            onChange={(e) => {
              setKilometers(e.target.value);
            }}
            value={kilometers}
          />
          <i>{Number(kilometers / 1.60934).toFixed(1)} miles</i>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            // flexDirection: {
            //   xs: "row",
            //   md: "column",
            // },
          }}
        >
          <InputLabel>
            from
            <TextField
              size="small"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </InputLabel>
          <InputLabel style={{ display: "flex", alignItems: "center" }}>
            to
            <TextField
              size="small"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </InputLabel>
        </Box>

        {/* <FormControl size="small" component="fieldset">
          <RadioGroup name="options"> */}
        <div style={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={sortBy === "start_time"}
                onClick={() => setSortBy("start_time")}
              />
            }
            label="sort by date"
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={sortBy === "distance"}
                onClick={() => setSortBy("distance")}
              />
            }
            label="sort by distance"
          />
        </div>
        {/* </RadioGroup>
        </FormControl> */}

        <Button
          style={{
            ...theme.typography.button,
            display: isUserValidated() ? "inline" : "none",
          }}
          onClick={copyToClipboard}
        >
          Copy url
        </Button>

        <div style={{ display: isUserValidated() ? "block" : "none", gap: 5 }}>
          <div>lat {lat}</div>
          <div>lng {lng}</div>
        </div>

        {msg && (
          <div
            style={{
              padding: 10,
              margin: 10,
              backgroundColor: theme.palette.warning.main,
            }}
            onClick={() => setMsg("")}
          >
            {msg}
          </div>
        )}
      </div>
      {/* <pre>{JSON.stringify(exportEvents, null, 3)}</pre> */}

      {isUserValidated() ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => setShowExport(true)}
            style={{
              ...theme.typography.button,
              display: showExport ? "none" : "inline",
            }}
          >
            paste
          </Button>
          <IconButton
            onClick={() => setShowExport(false)}
            style={{
              display: showExport ? "inline" : "none",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Button
            onClick={() => setExportEvents([])}
            sx={{
              ...theme.typography.button2,
              display: showExport ? "inline" : "none",
            }}
          >
            clear
          </Button>
          <Button
            onClick={() => doPaste()}
            sx={{
              ...theme.typography.button,
              display: showExport ? "inline" : "none",
            }}
          >
            paste
          </Button>
        </div>
      ) : null}
      <div style={{ display: showExport ? "block" : "none" }}>
        <textarea
          onChange={(e) => {
            // console.log(JSON.parse(e.target.value));
            try {
              setExportEvents(JSON.parse(e.target.value));
            } catch (err) {
              setExportEvents(exportEvents);
              setMsg(err.message);
            }
          }}
          value={
            JSON.stringify(exportEvents, null, 3)
              ? JSON.stringify(exportEvents, null, 3)
              : "[]"
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 40,
          alignItems: "flex-start",
        }}
      >
        {exSet &&
          exSet.map((row, i) => {
            return (
              <EventBox
                key={i}
                row={row}
                exportEvents={exportEvents}
                setExportEvents={setExportEvents}
              />
            );
          })}
      </div>
    </div>
  );
};

export default GetEvents;
