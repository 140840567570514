// CalendarList.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField, Button, Slider } from "@mui/material";
import EditEvent from "../components/Events/EditEvent.js";
import AddEvent from "../components/Events/AddEvent.js";
import GetEvents from "../components/Events/GetEvents.js";

import Header from "../components/Header/index.js";
import ValidateJwt from "../components/Login/ValidateJwt.js";

import isUserValidated from "../util/isUserValidated.js";

import { useTheme } from "@mui/material/styles";

import { appConfig } from "../config.js";

function ManageEvents({ trig }) {
  const theme = useTheme();
  const [events, setEvents] = useState([]);
  const [fields, setFields] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [kilometers, setKilometers] = useState(50);
  const [zip, setZip] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const [showPast, setShowPast] = useState(false);

  // Instead of setting a default object that’s not in your events list,
  // we start with a null value and show a label/placeholder.
  const [selectedEvent, setSelectedEvent] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserValidated()) {
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + "/" + window.location.search,
        },
      });
    }
  }, [trig]);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const fetchEvents = (pastEvents) => {
    console.log(pastEvents);
    let url = `${appConfig.NODEURL}api/events/?start=${
      new Date().toISOString().split("T")[0]
    }`;

    if (pastEvents === true) {
      url = `${appConfig.NODEURL}api/events/`;
    }
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setEvents(data.rows);
        setFields(data.fields);
        console.log(data.rows);
        // console.log(data.fields);
      })
      .catch((err) => console.error("Error fetching events:", err));
  };

  const [exSet, setExSet] = useState("");
  const [msg, setMsg] = useState("");
  const fetchExpanedEvents = () => {
    if (!zip) {
      setMsg("enter a zip code");
      return;
    }
    const startDate = new Date();
    const endDate = addDays(startDate, 365);

    // Convert dates to ISO strings
    const startISO = startDate.toISOString();
    const endISO = endDate.toISOString();

    // Optionally encode the parameters in case of any special characters
    const url = `${
      appConfig.NODEURL
    }api/expanded-events?start=${encodeURIComponent(
      startISO
    )}&end=${encodeURIComponent(
      endISO
    )}&lat=${lat}&lng=${lng}&kilometers=${kilometers}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        // setEvents(data.rows || data);
        // setFields(data.fields);
        setExSet(data);
      })
      .catch((err) => console.error("Error fetching events:", err));
  };

  useEffect(() => {
    const pastEvents = false;
    fetchEvents(pastEvents);
  }, []);

  return (
    <Header title="Manage Dances">
      <ValidateJwt />

      <div style={{ display: "flex", alignItems: "flex-start", gap: 10 }}>
        <Autocomplete
          size="small"
          style={{ minWidth: 250, maxWidth: 300 }}
          value={selectedEvent}
          onChange={(event, newValue) => {
            setSelectedEvent(newValue);
            setShowAdd(newValue ? false : true);
          }}
          options={events}
          getOptionLabel={(option) => option.name || ""}
          // If the options might be empty, add a filter to avoid errors:
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              placeholder="Select event to edit..."
              variant="standard"
            />
          )}
        />

        <div>count {events.length} </div>
        <Button
          onClick={() => {
            const pastEvents = !showPast;
            fetchEvents(pastEvents);
            setShowPast((prev) => !prev);
          }}
          style={{ ...theme.typography.button2 }}
        >
          {showPast ? "exclude past" : "list past"}
        </Button>
      </div>

      {selectedEvent?.id && !showAdd && (
        <EditEvent
          setSelectedEvent={setSelectedEvent}
          fetchEvents={fetchEvents}
          eventId={selectedEvent.id}
          onClear={() => setSelectedEvent(null)}
        />
      )}
      <AddEvent
        setSelectedEvent={setSelectedEvent}
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        fetchEvents={fetchEvents}
      />
      <GetEvents />
    </Header>
  );
}

export default ManageEvents;
