export const appConfig = {
  NODEURL: "/",
  PAYPAL_EMAIL: "sb-sq5nw7764494@business.example.com",
  PAYPAL_ENV: "https://www.paypal.com/cgi-bin/webscr",
  HOME: "https://register.louisvillecountrydancers.org/",
  PAYPAL_CLIENT_ID:
    "AUM_8Si-0AlnRFZv6_FtJYqbEvj8N5CMsOp5uytrbIQS_aZ_EEDty8-SdfKq8q1aX9-IUbkXSToL9B1Y",
  REG_DOMAIN: "register.louisvillecountrydancers.org",
DANCERSCODE: "LCD3233",
  PAYPAL_RESTBASEURL: "https://www.paypal.com",
EARLYBIRDDATE: "2024-09-30",
  EVENT: "Fleur",
PLAYERNAME: "2023 Videos",
  StartDate: "11/14/2024",
  EndDate: "11/16/2024",
GmapKey: "AIzaSyAKiS1t2frj23ena0nADxV5s8O33O9wiwI",
Zip:"40204",
};

const isEarlyBird = new Date(appConfig.EARLYBIRDDATE) > new Date();

export const prices = {
  Fleur: [130, 90, 135, 90],
  Friday: [35, 25, 40, 30],
  SaturdayAfternoon: [35, 25, 35, 24],
  SaturdayNight: [40, 30, 45, 27],
  Sunday: [35, 25, 30, 20],
};

export const prices2 = {
  Fleur: isEarlyBird ? [130, 90] : [135, 90],
  Friday: isEarlyBird ? [35, 25] : [40, 30],
  SaturdayAfternoon: isEarlyBird ? [35, 25] : [35, 24],
  SaturdayNight: isEarlyBird ? [40, 30] : [45, 27],
  Sunday: isEarlyBird ? [35, 25] : [30, 20],
};

const mprice = isEarlyBird
  ? Object.entries(prices)[0][1][0]
  : Object.entries(prices)[0][1][2];


export const allowed = false;

export const _fields = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  student: false,
  housing: false,
  dietary: "omnivore",
  price: mprice,
  unit_amount: mprice,
  quantity: 1,
  waltzattend: "select...",
  name: "dance_admission",
  event: "Fleur",
  createdOn: "",
  orderId: "",
};

export const contact = {
  email: "registrar.louisvillecountrydancers.org",
  message: "4138137109",
};

export const playlist = [
  {
    url: "https://youtu.be/FnH9Ie3ezTg",
    label: "2023 Saturday One",
  },
  {
    url: "https://youtu.be/6d3GKYzFbuI",
    label: "2023 Saturday Two",
  },
  {
    url: "https://youtu.be/IEPflexB8nQ",
    label: "2023 Saturday Three",
  },
  {
    url: "https://youtu.be/8zZZicFodms",
    label: "2023 Friday One",
  },
  {
    url: "https://youtu.be/Ip-HIXcUtXw",
    label: "2023 Friday Two",
  },
  {
    url: "https://youtu.be/kDO72-R3tOk",
    label: "2023 Friday Three",
  },
  {
    url: "https://youtu.be/jxLG3eQ-dSA",
    label: "2023 Friday Four",
  },
  {
    url: "https://youtu.be/S-MI7I5A0h8",
    label: "2023 Friday Five",
  },
];
