// EditEvent.js
import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  // Box,
  // Select,
  // MenuItem,
  // Autocomplete,
  Button,
  Zoom,
  IconButton,
  // Dialog,
  // DialogContent,
  // TextField,
  // Grid,
  // InputBase,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import CommonInputs from "./CommonInputs";
import { appConfig } from "../../config.js";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
function EditEvent({ eventId, onClear, setSelectedEvent, fetchEvents }) {
  const theme = useTheme();
  const [event, setEvent] = useState(null);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const handleDelete = (id) => {
    fetch(`${appConfig.NODEURL}api/events/${id}`, { method: "DELETE" })
      .then(() => setSelectedEvent(null))
      .then(() => fetchEvents())
      .catch((err) => console.error("Error deleting event:", err));
  };

  useEffect(() => {
    console.log("edit event");
    fetch(`${appConfig.NODEURL}api/events/${eventId}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setEvent(data);
        setHeight(data.image_height ? data.image_height : 0);
        setWidth(data.image_width ? data.image_width : 0);
      })
      .catch((err) => console.error("Error fetching event:", err));
  }, [eventId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    delete event.image;
    event.image_width = width;
    event.image_height = height;
    fetch(`${appConfig.NODEURL}api/events/${eventId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(event),
    })
      .then((res) => res.json())
      .then((data) => console.log("Event updated:", data))
      .catch((err) => console.error("Error updating event:", err));
  };

  if (!event) return <p>Loading...</p>;

  return (
    <Zoom in={true}>
      <div
        style={{
          boxShadow: theme.shadows[12],
          borderRadius: 10,
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex" }}>
            <IconButton
              // sx={{ ...theme.typography.button2 }}
              onClick={() => onClear()}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ ...theme.typography.h4 }}>Edit Dance</div>
          </div>
          <CommonInputs
            event={event}
            setEvent={setEvent}
            height={height}
            width={width}
            setHeight={setHeight}
            setWidth={setWidth}
          />
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <Button sx={{ ...theme.typography.button }} type="submit">
              Save Changes
            </Button>

            <ConfirmButtons
              label="delete"
              action={handleDelete}
              args={event.id}
              icon={
                <IconButton>
                  <DeleteForeverIcon />
                </IconButton>
              }
            />
          </div>
        </form>
      </div>
    </Zoom>
  );
}

export default EditEvent;
