// CalendarList.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField, Button, Slider } from "@mui/material";
import EditEvent from "../components/Events/EditEvent.js";
import AddEvent from "../components/Events/AddEvent.js";
import GetEvents from "../components/Events/GetEvents.js";

import Header from "../components/Header/index.js";
import ValidateJwt from "../components/Login/ValidateJwt.js";

import isUserValidated from "../util/isUserValidated.js";

import { useTheme } from "@mui/material/styles";

import { appConfig } from "../config.js";

function Rolodex({ trig }) {
  const theme = useTheme();
  const [events, setEvents] = useState([]);
  const [fields, setFields] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [kilometers, setKilometers] = useState(50);
  const [zip, setZip] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  // Instead of setting a default object that’s not in your events list,
  // we start with a null value and show a label/placeholder.
  const [selectedEvent, setSelectedEvent] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserValidated()) {
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + "/" + window.location.search,
        },
      });
    }
  }, [trig]);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const fetchEvents = () => {
    fetch(`${appConfig.NODEURL}api/events`)
      .then((res) => res.json())
      .then((data) => {
        setEvents(data.rows);
        setFields(data.fields);
        console.log(data.rows);
        // console.log(data.fields);
      })
      .catch((err) => console.error("Error fetching events:", err));
  };

  const [exSet, setExSet] = useState("");
  const [msg, setMsg] = useState("");
  const fetchExpanedEvents = () => {
    if (!zip) {
      setMsg("enter a zip code");
      return;
    }
    const startDate = new Date();
    const endDate = addDays(startDate, 365);

    // Convert dates to ISO strings
    const startISO = startDate.toISOString();
    const endISO = endDate.toISOString();

    // Optionally encode the parameters in case of any special characters
    const url = `${
      appConfig.NODEURL
    }api/expanded-events?start=${encodeURIComponent(
      startISO
    )}&end=${encodeURIComponent(
      endISO
    )}&lat=${lat}&lng=${lng}&kilometers=${kilometers}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        // setEvents(data.rows || data);
        // setFields(data.fields);
        setExSet(data);
      })
      .catch((err) => console.error("Error fetching events:", err));
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Header>
      <ValidateJwt />

      <div style={{ display: "block", alignItems: "flex-start", gap: 10 }}>
        <Autocomplete
          label="Select event to edit..."
          size="small"
          style={{ minWidth: 250, maxWidth: 300 }}
          // `selectedEvent` is an object from the `events` list or null.
          value={selectedEvent}
          // When a new event is selected, update state and hide the AddEvent form.
          onChange={(event, newValue) => {
            // console.log(newValue);
            setSelectedEvent(newValue);
            setShowAdd(newValue ? false : true);
          }}
          // The options are your list of events.
          options={events}
          // Tell Autocomplete how to get the string label from each event.
          getOptionLabel={(option) => option.name || ""}
          // If the options might be empty, you can add a filter to avoid errors:
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              placeholder="Select event to edit..."
              variant="outlined"
            />
          )}
        />

        <AddEvent
          setSelectedEvent={setSelectedEvent}
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          fetchEvents={fetchEvents}
        />
      </div>

      {selectedEvent?.id && !showAdd && (
        <EditEvent
          setSelectedEvent={setSelectedEvent}
          fetchEvents={fetchEvents}
          eventId={selectedEvent.id}
          onClear={() => setSelectedEvent(null)}
        />
      )}

      <GetEvents />
    </Header>
  );
}

export default Rolodex;
